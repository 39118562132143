import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import stationService from "../../../services/stationService";
import { QUERY_KEY_ALL_STATIONS } from "../query-keys";

const useGetUniqueStationShortNames = (): string[] => {
	const [uniqueStationShortNames, setUniqueStationShortNames] = useState<
		string[]
	>([]);

	const { data: stations } = useQuery({
		queryKey: [QUERY_KEY_ALL_STATIONS],
		queryFn: stationService.fetchStations,
	});

	useEffect(() => {
		if (stations && stations.length > 0) {
			setUniqueStationShortNames(stations.map(({ shortName }) => shortName));
		}
	}, [stations]);

	return uniqueStationShortNames;
};

export default useGetUniqueStationShortNames;
