import React, { useState } from "react";
import { Checkbox, Form, Button, AutoComplete, Divider, Row, Col } from "antd";
import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Issue, Station } from "../../../utilities/api/jelbi-dashboard-api";
import styles from "./index.module.scss";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";

export type MapFilterOptions = {
	// issues
	showDamageIssues: boolean;
	showDefectIssues: boolean;
	showPollutionIssues: boolean;
	showTrafficSafetyIssues: boolean;
	showOtherIssues: boolean;
	// type
	showPoints: boolean;
	showStations: boolean;
};

const CHECKBOX_LEFT_OFFSET = 1;

export const getFilteredStations = (
	stations: Station[],
	issues: Issue[],
	filters: MapFilterOptions
): Station[] => {
	const isFilteredForIssues =
		filters.showDamageIssues ||
		filters.showDefectIssues ||
		filters.showPollutionIssues ||
		filters.showTrafficSafetyIssues ||
		filters.showOtherIssues;
	const isFilteredForStationType = filters.showPoints || filters.showStations;

	const filteredStationIdsByIssues = new Set<string>();
	if (isFilteredForIssues) {
		issues.forEach((issue) => {
			if (
				(filters.showDamageIssues && issue.problem === "Beschädigung") ||
				(filters.showDefectIssues && issue.problem === "Defekt") ||
				(filters.showPollutionIssues && issue.problem === "Verschmutzung") ||
				(filters.showTrafficSafetyIssues &&
					issue.problem === "Verkehrssicherheit") ||
				(filters.showOtherIssues && issue.problem === "Sonstiges")
			) {
				filteredStationIdsByIssues.add(issue.station.id);
			}
		});
	}

	const filteredStations = isFilteredForIssues
		? filteredStationIdsByIssues
		: new Set<string>(stations.map(({ id }) => id));
	if (isFilteredForStationType) {
		stations.forEach((station) => {
			if (
				(filters.showPoints && !station.isPoint) ||
				(filters.showStations && station.isPoint)
			) {
				filteredStations.delete(station.id);
			}
		});
	}

	return stations.filter(({ id }) => filteredStations.has(id));
};

export const getStationTextSearchResult = (
	searchedStationId: string,
	stations: Station[]
): Station | undefined =>
	stations.find((station) => station.id === searchedStationId);

const transformStationsToStationFilterOptions = (stations: Station[]) =>
	stations.map((station) => {
		return {
			key: station.id,
			value: `${station.shortName} ${station.name}`,
		};
	});

type StationMapFilterProps = {
	callbackFunctions: {
		selectedFilters: (filterOptions: MapFilterOptions) => void;
		selectedStation: (stationId: string) => void;
		abortTextSearch: () => void;
	};
	stations: Station[];
};

type StationFilterOption = {
	key: string;
	value: string;
};

export function StationMapFilter({
	callbackFunctions,
	stations,
}: StationMapFilterProps): JSX.Element {
	const isCurrentViewMobile = useIsMobileView();
	const [autoCompleteOptions, setAutoCompleteOptions] = useState<
		StationFilterOption[]
	>(transformStationsToStationFilterOptions(stations));
	const [submitButtonText, setSubmitButtonText] = useState("Schließen");
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [isFilterActive, setIsFilterActive] = useState(false);
	const [isTextFilterActive, setIsTextFilterActive] = useState(false);
	const [mapFilterForm] = Form.useForm();

	const stationTypeOnChange = (event: CheckboxChangeEvent) => {
		const fields: MapFilterOptions = mapFilterForm.getFieldsValue();
		if (event.target.id === "showPoints" && fields.showStations) {
			mapFilterForm.setFieldsValue({ showStations: false });
		}
		if (event.target.id === "showStations" && fields.showPoints) {
			mapFilterForm.setFieldsValue({ showPoints: false });
		}
	};
	return (
		<div
			className={`${styles["map-filter"]} ${
				!isFilterOpen ? styles["map-filter--closed"] : ""
			}`}
		>
			<div
				role="presentation"
				onClick={() => {
					if (!isFilterOpen) {
						setIsFilterOpen(true);
					}
				}}
			>
				<p
					className={`${styles["filter-title"]} ${
						isFilterActive ? styles["filter-title--active"] : ""
					}`}
				>
					<FilterOutlined
						className={`${styles["filter-title__filter-icon"]} ${
							isFilterActive ? styles["filter-title__filter-icon--active"] : ""
						}`}
					/>
					{isFilterActive && !isFilterOpen ? (
						!isCurrentViewMobile && (
							<>
								<span className={styles["filter-title--active"]}>
									Filter aktiv
								</span>
								<CloseCircleOutlined
									className={styles["filter-title__reset_button"]}
									onClick={() => {
										mapFilterForm.resetFields();
										mapFilterForm.submit();
									}}
								/>
							</>
						)
					) : (
						<span>
							{!isFilterOpen && isCurrentViewMobile
								? ""
								: "Stationen suchen und filtern"}
						</span>
					)}
				</p>
			</div>
			{isFilterOpen && (
				<>
					<AutoComplete
						options={autoCompleteOptions}
						style={{ width: isCurrentViewMobile ? "95%" : "100%" }}
						onSelect={(_, opt) =>
							callbackFunctions.selectedStation(opt.key as string)
						}
						onClick={() => setIsTextFilterActive(true)}
						allowClear
						onSearch={(searchText) => {
							const filtered = stations.filter(
								({ name, shortName }) =>
									name.toLowerCase().includes(searchText.toLowerCase()) ||
									shortName.toLowerCase().includes(searchText.toLowerCase())
							);
							setAutoCompleteOptions(
								transformStationsToStationFilterOptions(filtered)
							);
						}}
						placeholder="Stationsnamen eingeben"
					/>
					{isTextFilterActive && (
						<Row
							className={styles["text-filter-controls"]}
							align="middle"
							gutter={[118, 0]}
						>
							<Col span={12}>
								<Button
									type="default"
									onClick={() => {
										setIsTextFilterActive(false);
										callbackFunctions.abortTextSearch();
									}}
								>
									Abbrechen
								</Button>
							</Col>
						</Row>
					)}
					{!isTextFilterActive && (
						<>
							<Divider style={{ backgroundColor: "#CCCCCC" }} />
							<Form
								form={mapFilterForm}
								name="basic"
								initialValues={{ remember: true }}
								autoComplete="off"
								onFinish={(selectedFilters: MapFilterOptions) => {
									callbackFunctions.selectedFilters(selectedFilters);
									setIsFilterOpen(false);
									if (
										Object.values(selectedFilters).some(
											(filterOption) => filterOption
										)
									) {
										setIsFilterActive(true);
									} else {
										setIsFilterActive(false);
									}
								}}
								onValuesChange={(_, allFilterOptionsValues) => {
									if (
										Object.values(allFilterOptionsValues).some(
											(filterOption) => filterOption
										)
									) {
										setSubmitButtonText("Filtern");
									} else {
										setSubmitButtonText("Schließen");
									}
								}}
							>
								{/* station issue filters */}
								<p className={styles["filter-option-title"]}>
									PROBLEMMELDUNGEN
								</p>
								<Form.Item
									className={styles["filter-option"]}
									wrapperCol={{ offset: CHECKBOX_LEFT_OFFSET }}
									name="showDamageIssues"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox checked={false}>Beschädigung</Checkbox>
								</Form.Item>
								<Form.Item
									className={styles["filter-option"]}
									wrapperCol={{ offset: CHECKBOX_LEFT_OFFSET }}
									name="showDefectIssues"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox checked={false}>Defekt</Checkbox>
								</Form.Item>
								<Form.Item
									className={styles["filter-option"]}
									wrapperCol={{ offset: CHECKBOX_LEFT_OFFSET }}
									name="showOtherIssues"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox>Sonstiges</Checkbox>
								</Form.Item>
								<Form.Item
									className={styles["filter-option"]}
									wrapperCol={{ offset: CHECKBOX_LEFT_OFFSET }}
									name="showTrafficSafetyIssues"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox>Verkehrssicherheit</Checkbox>
								</Form.Item>
								<Form.Item
									className={`${styles["filter-option"]} ${styles["filter-option--last-option"]}`}
									wrapperCol={{ offset: CHECKBOX_LEFT_OFFSET }}
									name="showPollutionIssues"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox>Verschmutzung</Checkbox>
								</Form.Item>

								{/* station type filters */}
								<p className={styles["filter-option-title"]}>STATIONSTYP</p>
								<Form.Item
									className={styles["filter-option"]}
									wrapperCol={{ offset: CHECKBOX_LEFT_OFFSET }}
									name="showPoints"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox
										id="showPoints"
										onChange={(checkboxEvent) =>
											stationTypeOnChange(checkboxEvent)
										}
									>
										Nur Jelbi-Punkte
									</Checkbox>
								</Form.Item>
								<Form.Item
									className={styles["filter-option"]}
									wrapperCol={{ offset: CHECKBOX_LEFT_OFFSET }}
									name="showStations"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox
										id="showStations"
										onChange={(checkboxEvent) =>
											stationTypeOnChange(checkboxEvent)
										}
									>
										Nur Jelbi-Stationen
									</Checkbox>
								</Form.Item>
								{/* submit */}
								<Form.Item className={styles["filter-submit"]}>
									<Button
										type="primary"
										htmlType="submit"
										className={styles["filter-submit-button"]}
									>
										{submitButtonText}
									</Button>
								</Form.Item>
							</Form>
						</>
					)}
				</>
			)}
		</div>
	);
}
