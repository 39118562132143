import { startOfDay } from "date-fns";

// stations
export const QUERY_KEY_ALL_STATIONS = "all-stations";
const QUERY_KEY_SINGLE_STATION = "station";

export const getStationQueryKey = (stationId: string): string[] => [
	QUERY_KEY_SINGLE_STATION,
	stationId,
];

// inspections
export const QUERY_KEY_ALL_INSPECTIONS = "all-inspections";

// issues
export const QUERY_KEY_ALL_ISSUES = "all-issues";
export const QUERY_KEY_ALL_TRACKED_ISSUES = "all-tracked-issues";

// msps
export const QUERY_KEY_ALL_MSPS = "all-msps";
export const QUERY_KEY_UNASSIGNED_DISTRIBUTIONS_MSP_IDS =
	"available-distributions-msp-ids";
const QUERY_KEY_SINGLE_MSP = "msp";
export const getMspQueryKey = (mspId: string): string[] => [
	QUERY_KEY_SINGLE_MSP,
	mspId,
];

export const getIssueCommentsQueryKey = (issueId: string): string[] => [
	"/issues",
	issueId,
	"/comments",
];

export const QUERY_KEY_STATION_ISSUES = "station-issues";
export const QUERY_KEY_STATION_TRACKED_ISSUES = "station-tracked-issues";

// photos
export const getPhotosQueryKey = (inspectionId: string): string[] => [
	"/inspections",
	inspectionId,
	"/photos",
];

// vehicleAvailability
const QUERY_KEY_VEHICLE_AVAILABILITY = "vehicle-availability";

export const getVehicleAvailabilityDayPerHourQueryKey = (
	startDay: Date,
	stationId: string
): string[] => [
	QUERY_KEY_VEHICLE_AVAILABILITY,
	stationId,
	startOfDay(startDay).toISOString(),
];

export const getVehicleAvailabilityStationLiveWeekQueryKey = (
	timePeriod: "LIVE" | "WEEK",
	stationId: string,
	withMsp: boolean
): string[] =>
	withMsp
		? [QUERY_KEY_VEHICLE_AVAILABILITY, "STATION_ID_MSP", stationId, timePeriod]
		: [
				QUERY_KEY_VEHICLE_AVAILABILITY,
				"STATION_ID_MODE",
				stationId,
				timePeriod,
			];
