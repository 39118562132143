import React, { ReactElement, useEffect, useState } from "react";
import { subYears } from "date-fns";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
	ActionIcon,
	Box,
	Checkbox,
	LoadingOverlay,
	MultiSelect,
	Pill,
	Stack,
	Text,
} from "@mantine/core";
import { IconSearch, IconTrashX } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { ok } from "@oazapfts/runtime";
import { DataTableColumn } from "mantine-datatable";
import { useNavigate, useParams } from "react-router-dom";
import inspectionService from "../../../services/inspectionService";
import {
	Inspection,
	deleteInspection,
} from "../../../utilities/api/jelbi-dashboard-api";
import { QUERY_KEY_ALL_INSPECTIONS } from "../../../utilities/client/query-keys";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import { isAdminOrHigher } from "../../../utilities/client/roles.util";
import { humanizedDateTimeFormat } from "../../../utilities/client/date-format";
import notifications from "./utils/InspectionsTable.notifications";
import Error from "../../Error/Error";
import TableWithPagination from "../../TableWithPagination/TableWithPagination";
import useStationForSelectData from "../../../utilities/client/hooks/useStationForSelectData";
import ReadInspection from "../ReadInspection";

function InspectionsTable(): ReactElement {
	const isMobile = useIsMobileView();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const userRoles = useGetUserRoles();
	const stationForSelectData = useStationForSelectData();

	const [records, setRecords] = useState<Inspection[]>([]);
	const [stationFilterValue, setStationFilterValue] = useState<string[]>([]);
	const [problemFilterValue, setProblemFilterValue] = useState<string[]>([]);

	const {
		data: inspections,
		isPending,
		isError,
	} = useQuery({
		queryKey: [QUERY_KEY_ALL_INSPECTIONS],
		queryFn: () => {
			const endDate = new Date().toISOString();
			const startDate = subYears(endDate, 1).toISOString();
			return inspectionService.fetchInspections(startDate, endDate);
		},
	});

	const stationFilter = (
		<MultiSelect
			maw="256px"
			label="Standorte"
			data={stationForSelectData}
			value={stationFilterValue}
			placeholder="Standort suchen"
			onChange={setStationFilterValue}
			leftSection={<IconSearch size={16} />}
			clearable
			searchable
			comboboxProps={{ withinPortal: false }}
		/>
	);

	const problemFilter = (
		<Checkbox.Group
			maw="256px"
			label="Probleme"
			value={problemFilterValue}
			onChange={setProblemFilterValue}
		>
			<Stack>
				<Checkbox value="WITHOUT" label="Begehungen ohne Problemmeldungen" />
				<Checkbox value="WITH" label="Begehungen mit Problemmeldungen" />
			</Stack>
		</Checkbox.Group>
	);

	const confirmDeleteInspection = (deleteInspectionId: string) => {
		modals.openConfirmModal({
			title: "Wirklich löschen?",
			children: <Text>Soll die Begehung wirklich gelöscht werden?</Text>,
			labels: { confirm: "Löschen", cancel: "Abbrechen" },
			confirmProps: { color: "red", fullWidth: isMobile },
			cancelProps: { fullWidth: isMobile },
			groupProps: {
				style: { flexDirection: isMobile ? "column-reverse" : "row" },
			},
			onConfirm: () =>
				ok(deleteInspection(deleteInspectionId))
					.then(() => {
						notifications.showDeleteInspectionSuccessfulNotification();
						queryClient.invalidateQueries({
							queryKey: [QUERY_KEY_ALL_INSPECTIONS],
						});
					})
					.catch(notifications.showDeleteInspectionFailedNotification),
			withCloseButton: false,
		});
	};

	const columns: DataTableColumn<Inspection>[] = isMobile
		? [
				{
					accessor: "station.name+issues",
					title: "Standort/Probleme",
					render: (inspection: Inspection) => {
						const problemCount = inspection.issues.length;
						const tagText =
							problemCount + (problemCount === 1 ? " Problem" : " Probleme");

						return (
							<>
								{problemCount > 0 && (
									<Pill bg="var(--mantine-color-red-1)">
										<span>{tagText}</span>
									</Pill>
								)}
								<div>{inspection.station.name}</div>
							</>
						);
					},
					filter: (
						<Stack>
							{problemFilter}
							{stationFilter}
						</Stack>
					),
					filtering:
						stationFilterValue.length > 0 || problemFilterValue.length > 0,
					width: 150,
				},
			]
		: [
				{
					accessor: "station.name",
					title: "Standort",
					filter: stationFilter,
					filtering: stationFilterValue.length > 0,
				},
				{
					accessor: "issues",
					title: "Probleme",
					render: (inspection) => (inspection.issues.length ? "ja" : "nein"),
					filter: problemFilter,
					filtering: problemFilterValue.length > 0,
				},
				{
					accessor: "issues.length",
					title: "Problemanzahl",
					render: (inspection) => <Box mx={40}>{inspection.issues.length}</Box>,
				},
			];

	columns.push({
		accessor: "date",
		title: "Datum",
		render: (inspection) =>
			humanizedDateTimeFormat(new Date(inspection.createdAt)),
	});

	if (isAdminOrHigher(userRoles)) {
		columns.push({
			accessor: "actions",
			title: "Aktion",
			render: (inspection) => (
				<ActionIcon
					variant="transparent"
					onClick={(event) => {
						event.stopPropagation();
						confirmDeleteInspection(inspection.id);
					}}
				>
					<IconTrashX />
				</ActionIcon>
			),
			width: "0%",
		});
	}

	useEffect(() => {
		if (inspections) {
			setRecords(
				inspections.results.filter((inspection) => {
					if (
						stationFilterValue.length > 0 &&
						!stationFilterValue.some(
							(stationFilterKey) => inspection.station.id === stationFilterKey
						)
					) {
						return false;
					}

					if (problemFilterValue.length === 2) {
						// both options selected
						return true;
					}
					if (problemFilterValue.includes("WITH")) {
						return inspection.issues.length > 0;
					}
					if (problemFilterValue.includes("WITHOUT")) {
						return inspection.issues.length === 0;
					}

					return true;
				})
			);
		}
	}, [inspections, stationFilterValue, problemFilterValue]);

	const { inspectionId } = useParams();
	const [selectedInspection, setSelectedInspection] = useState<Inspection>();
	useEffect(() => {
		if (inspectionId) {
			const sharedInspection = inspections?.results.find(
				(inspection) => inspection.id === inspectionId
			);
			if (sharedInspection) {
				setSelectedInspection(sharedInspection);
			}
		}
	});

	if (isPending) {
		return <LoadingOverlay visible />;
	}

	if (isError) {
		return <Error />;
	}

	return (
		<>
			<TableWithPagination<Inspection>
				columns={columns}
				records={records}
				onRowClick={({ record }) => navigate(`/inspections/${record.id}`)}
			/>
			{selectedInspection && (
				<ReadInspection
					key={`${selectedInspection.id}`}
					inspection={selectedInspection}
					onClose={() => navigate("/inspections/")}
				/>
			)}
		</>
	);
}

export default InspectionsTable;
