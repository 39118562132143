enum StationEquipment {
	Lighting = "Beleuchtung",
	Signage = "Beschilderung",
	CycleRack = "Fahrradbügel",
	GreenArea = "Grünanlage",
	Cube = "Kubus",
	ChargingSteles = "Ladesäulen",
	Marker = "Markierung",
	Switchboard = "Schaltschrank",
	ScooterRack = "Scooterhalterung",
	SmartBench = "Smart Bench",
	StationArea = "Stationsfläche",
	InformationStele = "Stele / Infovitrine",
	Wifi = "WLAN",
}

const stationEquipmentOptions = (
	Object.keys(StationEquipment) as (keyof typeof StationEquipment)[]
).map((item) => {
	return {
		key: StationEquipment[item],
		label: StationEquipment[item],
		value: StationEquipment[item],
	};
});

export default stationEquipmentOptions;
