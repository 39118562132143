import React from "react";
import L, { MarkerCluster } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { renderToString } from "react-dom/server";
import { Station } from "../../../utilities/api/jelbi-dashboard-api";
import styles from "./index.module.scss";
import StationMarker from "../StationMarker";
import ClusterMarker from "../ClusterMarker";

const NOT_SELECTED_PIN_OPACITY = 0.3;

type ActiveStationMarkersProps = {
	stations: Station[];
	setSelectedStation: (station: Station) => void;
	showStationGeofence: boolean;
	selectedStation?: Station;
	showStationInformation: boolean;
};

function ActiveStationMarkers({
	stations,
	setSelectedStation,
	showStationGeofence,
	selectedStation,
	showStationInformation,
}: ActiveStationMarkersProps): JSX.Element {
	const getPinOpacity = (station: Station): number => {
		return showStationInformation && station.id !== selectedStation?.id
			? NOT_SELECTED_PIN_OPACITY
			: 1.0;
	};

	const stationMarkers = stations.map((station) => {
		return (
			<StationMarker
				key={station.id}
				station={station}
				setSelectedStation={setSelectedStation}
				showStationGeofence={showStationGeofence}
				opacity={getPinOpacity(station)}
			/>
		);
	});

	const clusterIconCreateFunction = (cluster: MarkerCluster) => {
		const childMarker = cluster.getAllChildMarkers();

		const issueCount = childMarker
			.map((marker: L.Marker) =>
				parseInt(marker.options.alt?.split(",")[1] || "0", 10)
			)
			.reduce((accu: number, curr: number) => accu + curr, 0);

		const showPlannedClosureBadge = childMarker
			.map((marker: L.Marker) => marker.options.alt?.split(",")[3] || "false")
			.some((isPlannedClosureShown) => isPlannedClosureShown === "true");

		const showZeroMicromobilityAvailability = childMarker
			.map((marker: L.Marker) => marker.options.alt?.split(",")[4] || "false")
			.some(
				(isZeroMicromobilityAvailability) =>
					isZeroMicromobilityAvailability === "true"
			);

		const showOverMicromobilityToleranceLimit = childMarker
			.map((marker: L.Marker) => marker.options.alt?.split(",")[5] || "false")
			.some((isOverToleranceLimit) => isOverToleranceLimit === "true");

		const opacity =
			childMarker
				.map((marker: L.Marker) => marker.options.alt?.split(",")[0])
				.includes(selectedStation?.id) || !showStationInformation
				? 1
				: NOT_SELECTED_PIN_OPACITY;

		return new L.DivIcon({
			html: renderToString(
				<ClusterMarker
					childCount={cluster.getChildCount()}
					issueCount={issueCount}
					showPlannedClosureBadge={showPlannedClosureBadge}
					opacity={opacity}
					showZeroMicromobilityAvailabilityBadge={
						showZeroMicromobilityAvailability
					}
					showOverMicromobilityToleranceLimit={
						showOverMicromobilityToleranceLimit
					}
				/>
			),
			className: styles.badge__badgeBackground,
			iconSize: [38, 38],
		});
	};

	return (
		<MarkerClusterGroup
			maxClusterRadius={50}
			showCoverageOnHover={false}
			iconCreateFunction={clusterIconCreateFunction}
		>
			{stationMarkers}
		</MarkerClusterGroup>
	);
}

export default ActiveStationMarkers;
